import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { GoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';

export function GoogleLoginButton() {
  const {
    actions: { signInWithGoogle },
  } = useAuthenticationState();
  const { i18n } = useTranslation();

  return (
    <GoogleLogin text='signin' width='400px' locale={i18n.language} onSuccess={signInWithGoogle} />
  );
}
