import { useEffect } from 'react';

import { LoginLayout } from '../../components/LoginLayout/LoginLayout';
import { LOGIN_SUB_PASSWORD_ROUTE, LOGIN_SUB_SSO_ROUTE } from '../../constants/routes.constants';
import { useAuthenticationState } from '../../hooks/useAuthenticationState';

import { MFAForm } from './MFASection/MFAForm.container';
import { PasswordLoginSection } from './PasswordLoginSection/PasswordLogin.section';
import { SsoLoginSection } from './SsoLoginSection/SsoLogin.section';

import { Typography } from '@aircall/tractor-v2';
import { ExternalLink } from '@components/Link';
import { LoadingContainer, Signup, FormWrapper } from '@components/LoginLayout/Login.styles';
import { ResellerConfig } from '@config/resellers.config';
import { BACKEND_ERRORS_TRANSLATION_KEYS } from '@constants/errors.constants';
import { SIGNUP_LINK_URL } from '@constants/urls.constants';
import { Loading } from '@dashboard/library';
import { useLoginRedirect } from '@hooks/useLoginRedirect/useLoginRedirect';
import { useQueryParams } from '@hooks/useQueryParams';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

export default function LoginPage() {
  const { t } = useTranslation();
  const {
    actions: { signInSsoWithCode, setSignInError },
    authState: { signingIn, mfaInitiated },
  } = useAuthenticationState();
  const { searchParams } = useQueryParams();
  const code = searchParams.get('code');
  const hasSamlError = searchParams.has('error');
  const {
    featureSet: { shouldShowSignupLink, useEmailMfa },
  } = ResellerConfig;

  useLoginRedirect();

  useEffect(() => {
    if (code) {
      signInSsoWithCode(code);
    }
    // We only want to run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInSsoWithCode]);

  useEffect(() => {
    if (hasSamlError) {
      setSignInError(BACKEND_ERRORS_TRANSLATION_KEYS.CREDENTIALS_ERROR);
    }
  }, [hasSamlError, setSignInError]);

  return (
    /* on password login, if company has Multi Factor Authentication (MFA) enabled, show form to input OTP received on email */
    useEmailMfa && mfaInitiated ? (
      <MFAForm />
    ) : (
      <LoginLayout data-test='login-page'>
        <FormWrapper>
          {/* We want this loading to be on top to avoid re-rendering the login form and losing its state */}
          {signingIn && (
            <LoadingContainer>
              <Loading data-test='login-page-loading' />
            </LoadingContainer>
          )}

          <Routes>
            <Route path={LOGIN_SUB_PASSWORD_ROUTE} element={<PasswordLoginSection />} />
            <Route path={LOGIN_SUB_SSO_ROUTE} element={<SsoLoginSection />} />
          </Routes>

          {shouldShowSignupLink && (
            <Signup space='xxs' data-test='signup-link'>
              <Typography color='text-base'>{t('signup.no_account')}</Typography>
              <ExternalLink href={SIGNUP_LINK_URL} data-test='signup-link' target='_blank'>
                <Typography color='text-base'>{t('signup.create_account_link')}</Typography>
              </ExternalLink>
            </Signup>
          )}
        </FormWrapper>
      </LoginLayout>
    )
  );
}
