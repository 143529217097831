import { useCallback } from 'react';

import styled from 'styled-components';

import { PasswordLoginForm, LoginFormValues } from './PasswordLoginForm.container';

import { LockedOutlined } from '@aircall/icons';
import {
  Spacer,
  Flex,
  Typography,
  Button,
  getColor,
  ButtonWithChildren,
} from '@aircall/tractor-v2';
import { GoogleLoginButton } from '@components/GoogleLoginButton';
import { Or, Line } from '@components/LoginLayout/Login.styles';
import { ResellerConfig } from '@config/resellers.config';
import { LOGIN_SSO_ROUTE } from '@constants/routes.constants';
import { Gap } from '@dashboard/library';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace/useNavigateWithParamsReplace';
import { useTranslation } from 'react-i18next';

export const SsoSigninButton = styled<ButtonWithChildren>(Button)`
  color: ${getColor('neutral-400')};
  font-size: 16px;

  &:hover {
    color: ${getColor('text-base')};
  }
`;

export function PasswordLoginSection() {
  const { t } = useTranslation();
  const {
    actions: { signIn },
  } = useAuthenticationState();
  const navigate = useNavigateWithParamsReplace();

  const {
    featureSet: { shouldShowGoogleSignIn, enableSaml },
  } = ResellerConfig;

  const onSubmit = useCallback(
    (values: LoginFormValues) => {
      signIn(values);
    },
    [signIn]
  );

  const handleSso = useCallback(() => {
    navigate(LOGIN_SSO_ROUTE, undefined, { keepParams: true });
  }, [navigate]);

  const hasLoginOptions = enableSaml || shouldShowGoogleSignIn;

  return (
    <Spacer data-test='password-login-section' direction='vertical' space='xl' fluid>
      <Typography variant='headingBoldL' textAlign='center'>
        {t('login.sign_in')}
      </Typography>

      <Spacer direction='vertical' space='xl' fluid>
        <PasswordLoginForm onSubmit={onSubmit} />

        {hasLoginOptions ? (
          <Spacer data-test='login-options' direction='vertical' space='xl' fluid>
            <Flex justifyContent='center' alignItems='center'>
              <Or variant='bodyMediumS' color='grey.dark'>
                {t('signup.or')}
              </Or>
              <Line />
            </Flex>

            <Gap flexDirection='column'>
              {shouldShowGoogleSignIn && <GoogleLoginButton />}

              {enableSaml && (
                <SsoSigninButton
                  data-test='sso-login-button'
                  mode='link'
                  variant='alternative'
                  onClick={handleSso}
                >
                  <LockedOutlined />
                  {t('login.sso.sign_in_sso')}
                </SsoSigninButton>
              )}
            </Gap>
          </Spacer>
        ) : null}
      </Spacer>
    </Spacer>
  );
}
