import { useCallback } from 'react';

import { SsoSigninButton } from '../PasswordLoginSection/PasswordLogin.section';

import { SsoLoginForm } from './SsoLoginForm.container';

import { MailOutlined } from '@aircall/icons';
import { Spacer, Flex, Typography } from '@aircall/tractor-v2';
import { GoogleLoginButton } from '@components/GoogleLoginButton';
import { Or, Line } from '@components/LoginLayout/Login.styles';
import { ResellerConfig } from '@config/resellers.config';
import { LOGIN_ROUTE } from '@constants/routes.constants';
import { Gap } from '@dashboard/library';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace/useNavigateWithParamsReplace';
import { useTranslation } from 'react-i18next';

export function SsoLoginSection() {
  const { t } = useTranslation();
  const navigate = useNavigateWithParamsReplace();

  const {
    featureSet: { shouldShowGoogleSignIn },
  } = ResellerConfig;

  const handleSso = useCallback(() => {
    navigate(LOGIN_ROUTE, undefined, { keepParams: true });
  }, [navigate]);

  return (
    <Spacer data-test='sso-login-section' direction='vertical' space='xl' fluid>
      <Spacer direction='vertical' space='s' fluid>
        <Typography variant='headingBoldL' textAlign='center'>
          {t('login.sso.sign_in_sso')}
        </Typography>

        <Typography variant='bodyMediumS'>{t('login.sso.sign_in_sso_description')}</Typography>
      </Spacer>

      <SsoLoginForm />

      <Flex justifyContent='center' alignItems='center'>
        <Or variant='bodyMediumS' color='neutral-600'>
          {t('signup.or')}
        </Or>
        <Line />
      </Flex>

      <Gap flexDirection='column'>
        {shouldShowGoogleSignIn && <GoogleLoginButton />}

        <SsoSigninButton
          data-test='password-login-button'
          variant='alternative'
          mode='link'
          onClick={handleSso}
        >
          <MailOutlined />
          {t('login.sign_in_with_email_password')}
        </SsoSigninButton>
      </Gap>
    </Spacer>
  );
}
